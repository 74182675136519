import React, { Component } from 'react';
import Slider from 'react-slick';
import '../../../node_modules/slick-carousel/slick/slick.css';
import '../../../node_modules/slick-carousel/slick/slick-theme.css';
import TestimFamily from '../../images/testimfamily.png';

const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    autoplay: true,
    autoplaySpeed: 8000,
    arrows: false,
    slidesToShow: 1,
    slidesToScroll: 1,
};

const dotsSettings = {
    dots: false,
    autoplay: true,
    autoplaySpeed: 8000,
    infinite: true,
    speed: 500,
    arrows: false,
    slidesToShow: 6,
    slidesToScroll: 1,
    focusOnSelect: true,
};
class WhatParentsSay extends Component {
    constructor(props) {
        super(props);
        this.state = {
            nav1: null,
            nav2: null,
        };
    }

    componentDidMount() {
        this.setState({
            nav1: this.slider1,
            nav2: this.slider2,
        });
    }

    render() {
        return (
            <section className="page-section bg-secondary text-white testimsec">
                <h2 className="yellowtxt">What Parents Say</h2>
                <div
                    id="myCarousel"
                    className="carousel slide"
                    data-ride="carousel"
                >
                    <Slider
                        asNavFor={this.state.nav2}
                        ref={slider => (this.slider1 = slider)}
                        {...dotsSettings}
                        className="carousel-indicators"
                    >
                        <li></li>
                        <li></li>
                        <li></li>
                        <li></li>
                        <li></li>
                        <li></li>
                        
                    </Slider>
                    <Slider
                        {...settings}
                        asNavFor={this.state.nav1}
                        ref={slider => (this.slider2 = slider)}
                        className="carousel-inner"
                    >
                        <div className="item carousel-item active">
                            <p className="testimonial">
                                “Thanks to We Rock the Spectrum my daughter Avery, who is nonverbal and Autistic, has a place to go and play. …before WRTS opened, there was not a true safe indoor space for her to enjoy. A place where I didn’t have to worry about her needs and the ability of the staff to understand them.” </p>
                            <p className="overview">Megan T</p>
                        </div>
                        <div className="item carousel-item">
                            <p className="testimonial">
                            “This place is amazing! My wife and I had been looking for a good place to bring our 4 year old son for a while and heard about “We Rock the Spectrum Kids Gym” from a good friend.”                            </p>
                            <p className="overview">Jim M. </p>
                        </div>
                        <div className="item carousel-item">
                            <p className="testimonial">
                                “WRTS Hickory has changed our lives. Our son Jacob has Down Syndrome and prior to discovering WRTS, he didn’t have any locations or opportunities where he could truly be himself without having to apologize for his behavior. The wonderful staff and volunteers there are not only understanding, but kind, accepting, patient, and nurturing to all kids, especially those with special needs.  We have never felt more welcome than at WRTS Hickory.  Jacob has developed a close bond with Danny, whom he affectionately calls Dan Dan.  They met during the summer when Jacob attended Summer Camp at the gym.  We found out they had Parent’s Night Out, and my husband and I had the opportunity to go to dinner for the first time in years and we continue to do so.  Our weekly visits are the highlight of Jacob’s week and he’s never been happier than when he’s at WRTS Hickory with Dan Dan."</p>
                            <p className="overview">
                                Cheryl L</p>
                        </div>

                         <div className="item carousel-item">
                            <p className="testimonial">
                            “We love this place. My son is 5 and his dad feels like this is one of the few places they cay really play full-out INSIDE! The zip line, trampoline and crafts room are a huge hit with my son"</p>
                            <p className="overview">
                            Deborah S.  </p>
                        </div> 
                        <div className="item carousel-item">
                            <p className="testimonial">
                            "I firmly believe that God opens doors for His children. What a door this is !! If you get the chance .., go out and take your little ones, they have something here for everyone!  We belong.. and there is finally a place where we can play the way God made us without apologizing"</p>
                            <p className="overview">
                             Caroline Coley</p>
                          <img className="review-img" src="https://werockthespectrumhickory.wrtsfranchise.com/wp-content/uploads/2023/02/Website_Axle-updates.jpg" alt="rev" />
                        </div> 
                        <div className="item carousel-item">
                            <p className="testimonial">
                            "We had fun at We Rock The Spectrum - Hickory today!" </p>
                            <p className="overview">
                            Lindsey Goforth Trivett  </p>
                            <img className="review-img" src="https://werockthespectrumhickory.wrtsfranchise.com/wp-content/uploads/2023/02/Website_Axle-updates-1.jpg" alt="rev" />
                        </div> 
                       
                    </Slider>
                    <img
                        className="quoteimg"
                        src={TestimFamily}
                        alt="Testimonials family"
                    />
                </div>
            </section>
        );
    }
}

export default WhatParentsSay;
